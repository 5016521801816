<template>
    <main class="swcluster-main" id="swcluster-guide-analytics">
        <!-- page-banner -->
        <div class="page-banner">
            <div class="banner-image">
                <img src="@/assets/images/guide/mainbanner_analytics.jpg" alt="" />
            </div>
        </div>
        <!-- page-header -->
        <div class="page-header sw-divider">
            <div class="header-component">
                <CommonBreadcrumb :show-title="true" />
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body">
            <!-- page-contents -->
            <div class="page-contents">
                <!-- content-section:Amazon SageMaker -->
                <article class="content-major-section section-component">
                    <header class="major-header">
                        <h3 class="title title-en">Amazon SageMaker</h3>
                        <p class="description">
                            완전관리형 인프라, 도구 및 워크플로우를 사용하여 모든 사례에 <br />대한 기계 학습모델을 구축하고 훈련 및 배포할 수 있습니다.
                        </p>
                    </header>
                    <div class="major-texts">
                        <p class="text">
                            데이터 사이언티스트를 위한 IED와 비즈니스 분석가를 위한 코드 없는 인터페이스 등의 도구를 선택하여 더 많은 사람들이 기계 학습으로
                            혁신할 수 있도록 지원하세요.
                        </p>
                        <p class="text">
                            기계 학습을 위한 대량의 정형 데이터(테이블형 데이터) 및 비정형 데이터(사진, 비디오 및 오디오)에 액세스하고 레이블을 지정하고
                            처리하세요.
                        </p>
                        <p class="text">
                            최적화된 인프라로 교육 시간을 몇 시간에서 몇 분으로 단축하세요. 특별히 제작된 도구를 사용하여 팀 생산성을 최대 10배까지 높이세요.
                        </p>
                        <p class="text">조직 전체에서 MLOps 사례를 자동화하고 표준화하여 대규모 모델을 구축, 훈련, 배포 및 관리하세요.</p>
                    </div>
                </article>
                <article class="content-banner-section">
                    <div class="banner-item">
                        <img src="@/assets/images/guide/banner_analytics_01.png" alt="" />
                    </div>
                </article>
                <!-- content-section:모두가 ML로 혁신 가능하게 지원 -->
                <article class="content-section">
                    <div class="section-component">
                        <header class="section-header">
                            <h3 class="title title-sm">모두가 ML로 혁신 가능하게 지원</h3>
                        </header>
                        <div class="symbol-list-container card-image-container">
                            <ul class="symbol-list" data-ratio="5x4">
                                <!-- symbol-item -->
                                <li class="symbol-item">
                                    <div class="symbol-wrap">
                                        <div class="symbol"><img src="@/assets/images/guide/symbol_1_1.svg" alt="" /></div>
                                        <p class="symbol-content">SageMaker Studio를 사용하여 데이터를 <br />준비하고 모델을 구축, 훈련 및 배포하세요.</p>
                                        <div class="symbol-links">
                                            <a href="https://aws.amazon.com/ko/sagemaker/data-scientist/" class="link" target="_blank"
                                                >데이터 사이언티스트 <br />SageMaker <i class="icon-link"></i
                                            ></a>
                                        </div>
                                    </div>
                                </li>
                                <!-- symbol-item -->
                                <li class="symbol-item">
                                    <div class="symbol-wrap">
                                        <div class="symbol"><img src="@/assets/images/guide/symbol_1_2.svg" alt="" /></div>
                                        <p class="symbol-content">SageMaker MLOps를 사용하여 대규모로 <br />모델을 배포하고 관리하세요.</p>
                                        <div class="symbol-links">
                                            <a href="https://aws.amazon.com/ko/sagemaker/" class="link" target="_blank"
                                                >기계 학습 엔지니어 <br />SageMaker <i class="icon-link"></i
                                            ></a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </article>
            </div>
        </div>
        <!-- //page-body -->
    </main>
</template>

<script>
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
export default {
    name: 'Analytic',
    components: {CommonBreadcrumb},
};
</script>
